import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import createRoutes from '../src/routes';
import './styles/global.scss';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { Buffer } from 'buffer';
import config from './config';
function App() {
  
  const manifestUrl = config.ton_manifestUrl;

  return (
    <TonConnectUIProvider
      manifestUrl={manifestUrl}
      actionsConfiguration={{
        twaReturnUrl: 'https://t.me/TeleMiniAppBrianBot/coinmaster'
      }}
    >

      <BrowserRouter>
        {createRoutes()}
      </BrowserRouter>
    </TonConnectUIProvider>
  );
}
console.log('= ENV =', process.env.REACT_APP_ENV);

export default App;
