import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router';
import {
  HashRouter,
  Route,
  RouteComponentProps,
  BrowserRouter as Router,
  Switch,
  withRouter,
} from 'react-router-dom';
import ArcadeCenterPage from './pages/ArcadeCenterPage';

/**
 * Main App routes.
 */

const Routes: FC<RouteComponentProps> = () => {
  const { pathname } = useLocation();
  // scroll to top when route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Switch>
        <Route path={'/'} component={ArcadeCenterPage} />
      </Switch>
    </>
  );
};

const RoutesHistory = withRouter(Routes);

const routing = function createRouting() {
  return (
    <>
      <HashRouter>
        <Router>
          <RoutesHistory />
        </Router>
      </HashRouter>
    </>
  );
};
/**
 * Wrap the app routes into router
 *
 * PROPS
 * =============================================================================
 * @returns {React.Node}
 */
export default routing;
