import React from 'react';
import { FC } from 'react';
import { BasePage } from '../../layouts/index';
import PartBoxGame from './PartBoxGame';
import '../../styles/pages/ArcadeCenterPage.scss';
import TonSpace from './TonSpace';

const ArcadeCenterPage: FC = () => {
  return (
    <BasePage className="arcade-page">
      <div className="arcade-page__content">
         <PartBoxGame />
         {/* <TonSpace /> */}
      </div>
    </BasePage>
  );
};

export default ArcadeCenterPage;
